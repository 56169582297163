/**
 * Common element styling
 *  I.e., styling a select, an input, checkbox, radio buttons, buttons goes in here
 *  Mixins also belong here, as well as Bootstrap override
 */

// Set the text selection color
// For some odd reason, the browsers don't like the moz selection and selection
//  in one block...so thus the reason for the mixin
.selection() {
  background: #fe9524;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  .selection();
}
::selection {
  .selection();
}

// Removes the validation messages for forms
form input::-webkit-validation-bubble-message,
form select::-webkit-validation-bubble-message,
form textarea::-webkit-validation-bubble-message {
  display: none !important;
}

// No underline
a:hover {
  text-decoration: none;
}

.text-truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.triangle-icon {
  content: " ";
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 9px solid black;
  position: absolute;
  left: 0px;
}
.triangle-icon-active {
  content: " ";
  border-right: 5px solid transparent;
  border-top: 9px solid black;
  border-left: 5px solid transparent;
  position: absolute;
  left: 0px;
}

/**
 * Button Styling
 */

.btn {
  border-radius: 4px;
  border: 0px transparent;
  background-color: @grey-darkest;
  color: white;
  font-weight: 100;
  font-size: 14px;
  padding: 7px 15px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  min-height: 34px;
  position: relative;

  // Standard styling (orange)
  background-color: @orange-lighter;
  &:hover {
    box-shadow: inset 0 -3px 2px -1px @orange;
    color: white;
  }
  &:active {
    background-color: @orange;
  }
  &:focus {
    outline: 0;
    color: white;
  }
  .small-loading-indicator {
    float: left;
    margin-left: -5px;
    margin-right: 4px;
    margin-top: 3px;
  }
  &:disabled,
  .disabled {
    pointer-events: none;
    cursor: default;
  }
  &:disabled:active,
  &.disabled:active,
  &:disabled:hover,
  &.disabled:hover {
    cursor: default;
    background-color: @orange-lighter;
  }
  &.sm {
    height: 10px;
    padding: 13px 9px;
    margin: 0;
    line-height: 0px;
    font-size: 13px;
    min-height: 15px;
  }
  &.light-blue,
  &.light {
    background-color: @blue3;
    &:hover {
      background-color: @blue3;
      box-shadow: inset 0 -3px 2px -1px @blue3-darkest;
    }
    &:active {
      background-color: @blue3-darkest;
    }
    &:disabled:active,
    &.disabled:active,
    &:disabled:hover,
    &.disabled:hover {
      backgroud-color: @blue3;
    }
  }
  &.dark-blue,
  &.dark {
    background-color: @blue2-darker;
    &:hover {
      box-shadow: inset 0 -3px 2px -1px @blue2-lighter;
    }
    &:active {
      background-color: @blue2-lighter;
    }
    &:disabled:active,
    &.disabled:active,
    &:disabled:hover,
    &.disabled:hover {
      background-color: @blue2-darker;
      box-shadow: none;
    }
  }
  &.plain {
    background-color: transparent;
    color: @grey-darkest;
    &:hover {
      box-shadow: none;
    }
    &:active {
      background-color: transparent;
    }
  }
  &.add-full {
    padding-left: 29px;
    .icon-plus;
    &:before {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 7px;
      left: 9px;
      font-size: 15px;
    }
  }
  &:disabled,
  &.disabled {
    &:hover {
      box-shadow: none;
    }
    opacity: 0.5;
  }
  // Mixin for other buttons
  .icon-btn() {
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    min-height: 0px;
    background-color: transparent;
    width: 17px;
    &:before {
      font-size: 18px;
    }
    &:hover {
      box-shadow: none;
    }
    &:active {
      background-color: transparent;
    }
    &.hide-text {
      // Hide the text inside the button
      height: 0;
      overflow: hidden;
      display: inline-block;
      padding-top: 16px;
    }
  }
  &.icon-btn {
    .icon-btn;
  }
  &.del {
    .icon-btn;
    .icon-delete;
    height: 30px;
    width: 19px;
    color: @red;
    &:before {
      height: 24px;
      width: 19px;
    }
    &:active:before {
      color: lighten(@red, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.delete-3 {
    .icon-btn;
    .icon-delete-3;
    &:before {
      color: @red;
      width: 10px;
      height: 10px;
    }
    &:active:before {
      color: lighten(@red, 10%);
    }
    &.white:before {
      color: white;
    }
    &.white:active:before {
      color: darken(white, 10%);
    }
  }
  &.add,
  &.plus {
    .icon-btn;
    .icon-plus;
    color: @grey-darkest;
    width: 20px;
    height: 26px;
    overflow: hidden;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.edit {
    .icon-btn;
    .icon-edit;
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white:before {
      color: white;
    }
    &.white:active:before {
      color: darken(white, 10%);
    }
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }

    // Styling for top toolbar, but messes up other uses
    //padding-left: 5px;
    //padding-right: 5px;
    //height: auto;
    //width: auto;
    //
    //&:before {
    //  font-size: 20px;
    //}
    //&:hover {
    //  background-color: @link-hover-color;
    //}
  }

  &.menu-data {
    .icon-btn;
    .icon-menu-data;
    color: @grey-darkest;
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
    width: auto;

    &:before {
      font-size: 20px;
    }
    &:hover {
      background-color: @link-hover-color;
    }
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white:before {
      color: white;
    }
    &.white:active:before {
      color: darken(white, 10%);
    }
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
  }
  &.auto-import {
    .icon-btn;
    .icon-upload;
    width: 22px;
    height: 24px;
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.green {
      color: @green;
      &:active:before {
        color: lighten(@green, 10%);
      }
    }
  }
  &.download {
    .icon-btn;
    .icon-download;
    width: 23px;
    height: 23px;
    overflow: hidden;
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
  }
  &.location {
    .icon-btn;
    .icon-location;
    color: @grey-darkest;
    width: 21px;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
  }
  &.draggable {
    .icon-btn;
    .icon-draggable;
    color: @grey-darkest;
    width: 23px;
    height: 20px;
    &:before {
      font-size: 20px;
    }
    cursor: move;
  }
  &.settings {
    .icon-btn;
    .icon-settings;
    width: 24px;
    height: 26px;
    &:before {
      color: white;
    }
  }
  &.scissors {
    .icon-btn;
    width: 20px;
    &:before {
      width: 19px;
      height: 30px;
    }
    .icon-scissors;
  }
  &.unarchive {
    .icon-btn;
    .icon-unarchive;
    &:before {
      font-size: 18px;
    }
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.archive {
    .icon-btn;
    .icon-archive;
    &:before {
      font-size: 18px;
    }
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.dependency-graph {
    .icon-btn;
    .icon-share;
    font-size: 23px;
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.triangle {
    .icon-btn;
    &:before {
      .triangle-icon;
    }
    &.active:before {
      .triangle-icon-active;
    }
  }
  &.filters {
    .icon-btn;
    .icon-menu-tools;
    width: 19px;
  }
  &.link {
    .icon-btn;
    .icon-link;
  }
  &.schedule {
    .icon-btn;
    .icon-schedule;
  }
  &.copy {
    .icon-btn;
    .icon-copy;
    height: 21px;
    color: @grey-darkest;
  }
  &.arrow-down {
    .icon-btn;
    .icon-arrow-down;
  }
  &.arrow-up {
    .icon-btn;
    .icon-arrow-up;
  }
  &.arrow-right {
    .icon-btn;
    .icon-arrow-right;
    &:before {
      font-size: 10px;
    }
  }
  &.information {
    .icon-btn;
    .icon-information;
    &:before {
      font-size: 18px;
    }
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.view {
    .icon-btn;
    .icon-view;
    &:before {
      font-size: 18px;
    }
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
  &.data {
    .icon-btn;
    .icon-data;
    &:before {
      font-size: 18px;
    }
    color: @grey-darkest;
    &:active:before {
      color: lighten(@grey-darkest, 10%);
    }
    &.white {
      color: white;
      &:active:before {
        color: darken(white, 10%);
      }
    }
  }
}

.btn-group {
  > button:nth-child(1) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-right: 0px;
  }
  > button:nth-child(2) {
    border-left: 1.5px solid #f39848;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -3px;
  }
  button > .caret {
    border: none;
    &:before {
      position: absolute;
      top: 13px;
      left: 50%;
      margin-left: -6px;
      color: white;
    }
  }
}

// Styling for selects
// Note: don't ever use .form-control with a select, it will mess up the styling completely
.select-holder {
  @select-height: 35px;
  .icon-arrow-down-after;
  cursor: pointer;
  width: 135px;
  height: @select-height;
  background-color: white;
  float: left;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  border: 2px solid @grey-dark;
  position: relative;
  padding: 0px;
  &:after {
    font-size: 7px;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
    line-height: 35px;
    color: @grey-darkest;
    pointer-events: none;
  }
  &.invalid {
    border: 2px solid @red;
  }
  &.blue {
    &:after {
      color: @blue-lighter;
    }
  }
  &.grey,
  &.gray {
    color: grey;
  }
  &.md {
    width: 85px;
    > select {
      width: 75px;
    }
  }
  &.sm {
    width: 55px;
    > select {
      width: 45px;
    }
  }
  > select::-ms-expand {
    display: none;
  }
  > select {
    .text-truncate;
    cursor: pointer;
    margin: 2px;
    padding: 4px 18px 4px 5px;
    border: 1px solid @grey;
    color: @grey-darkest;
    background: transparent;
    width: 98%;
    height: @select-height -3;
    line-height: @select-height -3;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;

    // Gets rid of the nasty blue ring in webkit browsers
    &:focus {
      outline: 2px solid @orange-darker;
      -webkit-box-shadow: inset 0 0 0 @orange-darker, 0 0 6px @orange-darker;
      box-shadow: inset 0 0 0 @orange-darker, 0 0 6px 2px @orange-darker;
      outline-offset: 0px;
      border-radius: 0px;

      // @todo: causes flash in Chrome 50, issue #1387
      // -webkit-transition: outline ease-in-out .15s,box-shadow ease-in-out .15s;
      // transition: outline ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    &.disabled,
    &:disabled {
      font-style: italic;
      opacity: 0.4;
    }
  }
}
select[multiple] {
  border-radius: 4px;
  border: 1px solid transparent;
  color: @grey-darkest;
  optgroup {
    font-weight: 100;
    font-size: 12px;
  }
}

// Multi-selection
.multiselect {
  .select-holder;
  .toggle {
    float: left;
    width: 100%;
    line-height: 30px;
    padding-left: 5px;
  }
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    width: 160px;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 0;
    margin: 0px;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  .option {
    float: left;
    width: 100%;
    height: 25px;
    input {
      width: 0;
      height: 0;
    }
    label.checkbox {
      margin: 0px;
      float: left;
      width: 100%;
      padding-left: 25px;
      .text {
        .text-truncate;
        float: left;
        width: 100%;
        line-height: 17px;
      }
    }
    ._checked + label.checkbox:after {
      left: 8px;
    }
    label.checkbox:before {
      left: 6px;
    }
  }
}

.disabled-state-mixin() {
  &.disabled,
  &:disabled,
  &[disabled] {
    background-color: darken(@grey-dark, 1%);
  }
}

// Inputs

input[type="text"],
input[type="password"],
input[type="number"],
textarea,
.input-styling {
  border: 2px solid @grey-dark;
  border-radius: 4px;
  padding-left: 7px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: @grey-darkest;
  outline: 0;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
  margin: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  &::-webkit-input-placeholder {
    color: @grey-darker;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: @grey-darker;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: @grey-darker;
  }
  &:-ms-input-placeholder {
    /* IE */
    color: @grey-darker;
  }

  // Remove the clear button from IE
  &::-ms-clear {
    width: 0;
    height: 0;
  }
  &.invalid,
  &.error,
  &.ng-invalid.ng-dirty {
    color: @red;
    border-color: @red;
    &:focus {
      border-color: @red;
      -webkit-box-shadow: inset 0 0 0 @red, 0 0 6px @red;
      box-shadow: inset 0 0 0 @red, 0 0 6px @red;
    }
  }
  &:focus {
    .input-focus();
  }
  &.sm {
    width: 45px;
  }
  &.md {
    width: 65px;
  }
}

.input-search {
  .icon-search;
  .disabled-state-mixin;
  position: relative;
  &:before {
    position: absolute;
    line-height: 39px;
    left: 12px;
    color: darken(@grey-dark, 1%);
    font-size: 15px;
  }
  input {
    padding-left: 28px;
  }
}

.input-focus(@color: @orange-darker) {
  border-color: @color;
  -webkit-box-shadow: inset 0 0 0 @color, 0 0 6px @color;
  box-shadow: inset 0 0 0 @color, 0 0 6px @color;
}
input[type="text"],
input[type="password"],
textarea {
  height: 34px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
.radio[disabled],
.radio-inline[disabled],
.checkbox[disabled],
.checkbox-inline[disabled],
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] .radio,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox-inline,
html input[disabled] {
}

input[type="checkbox"] {
  width: 0;
  visibility: hidden;
  &[disabled] + .checkbox {
    opacity: 0.75;
    &:before {
      background-color: lighten(@grey-dark, 2%);
    }
  }
}
input[type="checkbox"]._checked + label.checkbox {
  .icon-checkmark-after;
  &.blue {
    // not really used much anymore
    .icon-checkmark-after;
    color: #8ac0e0;
  }
  &:after {
    position: absolute;
    top: -4px;
    left: 2px;
    font-size: 17px;
    color: @grey-darkest;
    pointer-events: none;
  }
}
label.checkbox {
  display: inline-block;
  cursor: pointer;
  font-weight: 100;
  position: relative;
  padding-left: 20px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    border-bottom: 1px;
    background-color: @grey-dark;
    border: 1px solid #ddd;
  }
  &.dark {
    &:before {
      background-color: @grey-darker;
    }
  }
  &.white {
    &:before {
      background-color: white;
    }
  }
}

input[type="radio"] {
  visibility: hidden;
  &:checked + label {
    &:after {
      visibility: visible;
    }
  }
  + label {
    cursor: pointer;
    position: relative;
    margin-left: 17px;
    &:before {
      cursor: pointer;
      content: " ";
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: @grey;
      position: absolute;
      left: -22px;
    }
    &:after {
      cursor: pointer;
      visibility: hidden;
      content: " ";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: @grey-darkest;
      position: absolute;
      left: -18px;
      top: 4px;
    }
  }
}

// Checkbox switches
.checkbox-switch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: 3px;
  background: #d2d5d6; /* Old browsers */
  background: -moz-linear-gradient(top, #d2d5d6 0%, #f3f6f8 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d2d5d6), color-stop(100%, #f3f6f8)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d2d5d6 0%, #f3f6f8 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d2d5d6 0%, #f3f6f8 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d2d5d6 0%, #f3f6f8 100%); /* IE10+ */
  background: linear-gradient(to bottom, #d2d5d6 0%, #f3f6f8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2d5d6', endColorstr='#f3f6f8',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  input[type="checkbox"] {
    display: none;
  }
  label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #cbcbcb;
    border-radius: 20px;
    margin-bottom: 0px;
    .inner {
      width: 200%;
      margin-left: -100%;
      -moz-transition: margin 0.3s ease-in 0s;
      -webkit-transition: margin 0.3s ease-in 0s;
      -o-transition: margin 0.3s ease-in 0s;
      transition: margin 0.3s ease-in 0s;
      .inner-on,
      .inner-off {
        float: left;
        width: 50%;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-size: 14px;
        font-weight: 100;
        color: white;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 0px;
        padding-left: 0px;
      }
      .inner-on {
        background-color: @orange-lighter;
        color: white;
        padding-left: 15px;
        font-size: 11px;
      }
      .inner-off {
        background-color: @grey-dark;
        color: @grey-blue-darkest;
        text-align: right;
        font-size: 11px;
        padding-right: 5px;
      }
    }
    .switch {
      width: 20px;
      margin-right: 11px;
      background: #fff;
      border: 1px solid #cfe0e6;
      border-radius: 20px;
      position: absolute;
      top: 8px;
      bottom: 9px;
      right: 48px;
      -moz-transition: all 0.3s ease-in 0s;
      -webkit-transition: all 0.3s ease-in 0s;
      -o-transition: all 0.3s ease-in 0s;
      transition: all 0.3s ease-in 0s;
    }
  }
  input[type="checkbox"]:checked + label .inner {
    margin-left: 0;
  }
  input[type="checkbox"]:checked + label .switch {
    right: -3px;
  }
}

/**
 * Style mixins
 */

// Mixins for data display (list emails, groups/imports, collections, indicator data…etc, etc)
.data-table {
  width: 100%;
  margin-top: 15px;
  margin-left: 30px;
  padding-left: 0px;
  list-style-type: none;
  tr,
  li {
    background-color: @grey-dark;
    height: 62px;
    border-bottom: 7px solid white;
    th,
    td,
    .td {
      padding-left: 13px;
    }
  }
  li {
    .td {
      float: left;
      line-height: 57px;
    }
    .controls {
      float: right;
      line-height: 48px;
      .btn {
        height: 36px;
      }
    }
  }
  .controls {
    text-align: right;
    padding-right: 15px;
    .btn {
      margin-right: 0px;
      margin-top: -5px;
      height: 25px;
      vertical-align: middle;
      &.orange {
        margin-top: 0px;
      }
    }
  }
}

.striped-table {
  td,
  th {
    padding: 10px 15px;
  }
  th {
    font-weight: 400;
    background-color: @blue3;
    color: white;
  }
  tr:nth-child(odd) {
    background-color: @grey-dark;
  }
  tr:nth-child(even) {
    background-color: white;
  }
  .controls {
    background-color: white;
    padding: 10px 0px;
  }
}

.no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}

.ui-sortable-handle {
  .no-select;
  cursor: move;
}

/**
* Angular directives (epi- stuff)
*/

.epi-paging {
  .no-select;
  .prev,
  .next {
    cursor: pointer;
  }
  .prev {
    margin-right: 5px;
  }
  .next {
    margin-left: 5px;
  }
}

datepicker,
[uib-datepicker] {
  > table:focus {
    outline: 0;
  }
  .glyphicon-chevron-left {
    .icon-arrow-left;
  }
  .glyphicon-chevron-right {
    .icon-arrow-right;
  }
  th:nth-child(2) {
    text-align: center;
  }
  .btn {
    .btn.plain();
    padding: 1px;
    min-height: 27px;
    font-weight: 500;
    .text-muted {
      color: lighten(@grey-darkest, 15%);
      font-weight: 100;
    }
    &[disabled] {
      &:hover {
        background-color: transparent;
      }
    }
    &.btn-info {
      border: 1px solid @grey-darkest;
      border-radius: 50%;
      padding: 0px;
      background-color: transparent;
    }
  }
}

#main-console {
  background-color: whitesmoke;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 432px; /** Cheesy! Don't do this! **/

  // Classes prefixed with 'console-'
  .console- {
    &closed {
      height: 0;
    }

    &split {
      /* max-height: 50%; */
    }

    &maximized {
      height: 100%;
    }

    &header {
      background-color: @blue2;
      align-items: center;
      display: flex;
      flex-shrink: 0;

      > * {
        margin: 5px;
        color: white;
      }

      > .console-window-control.btn {
        margin-bottom: 0;
      }
    }

    &content {
      background-color: whitesmoke;
      display: flex;
      height: 100%;

      > * {
        flex-grow: 1;
      }
    }

    &footer {
      background-color: @grey-dark;

      > * {
        margin: 5px;
      }
    }
  }
}

.dock {
  &-bottom {
    position: fixed;
    width: 100%;
    bottom: 0px;
  }

  &-top {
    position: fixed;
    width: 100%;
    top: 0px;
  }
}

/**
 * Bootstrap overrides
 */
.navbar {
  border: none;
  background: @blue;
  color: white !important;
  font-weight: 100;
}
input[type="text"],
input[type="text-area"] {
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
}
.caret {
  border: none;
  .icon-arrow-down;
  &:before {
    font-size: 11px;
    color: @blue-lighter;
  }
  &.up {
    .icon-arrow-up;
  }
}
#content .row {
  margin-right: 0px;
  margin-left: 0px;
}
.modal-backdrop {
  z-index: (@zindex-modal-background) !important;
}
.modal {
  padding-top: 10%;
  z-index: (@zindex-modal) !important;
  overflow-y: auto;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: (@zindex-modal + 1);
    padding: 0;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
    &:hover,
    &:focus {
      color: @close-color;
      text-decoration: none;
      cursor: pointer;
      .opacity(0.5);
    }
  }
  .modal-header,
  .modal-footer {
    border: none;
    margin-top: 0;
  }
  .modal-body {
    border-top: 1px solid @modal-header-border-color;
    border-bottom: 1px solid @modal-header-border-color;
    padding-bottom: 10px;
    .clearfix;
  }
  .modal-error {
    background-color: @state-danger-bg;
    border-top: 1px solid @modal-footer-border-color;
    border-bottom: 1px solid @modal-footer-border-color;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal-addendum {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &.text-success {
      background-color: @state-success-bg;
      border-top: 1px solid @modal-footer-border-color;
      border-bottom: 1px solid @modal-footer-border-color;
    }

    &.text-danger {
      background-color: @state-danger-bg;
      border-top: 1px solid @modal-footer-border-color;
      border-bottom: 1px solid @modal-footer-border-color;
    }
  }
  .modal-content {
    h4 {
      font-weight: 400;
      font-size: 20px;
    }
    font-size: 18px;
    background-color: @grey;
  }
  .controls {
    height: 100%;
    width: 100%;
    background-color: @grey;
    padding: 20px 10px 10px 0px;
    float: right;
    button,
    .btn {
      float: right;
    }
  }
}
.modal.full-width {
  .modal-dialog {
    width: 83%;
    margin-left: 215px;
    padding-left: 2%;
    margin-right: 0px;
  }
}
body.modal-open {
  margin-right: 0px;
}
.sliding-panel {
}
.user-selector {
  position: relative;
  margin-bottom: 10px;
  .users {
    position: relative;
  }
  .text {
    .text-truncate;
    padding-right: 25px;
    width: 100%;
  }
  .del {
    position: absolute;
    top: -4px;
    right: 0px;
  }
  .add {
    position: absolute;
    top: 5px;
    right: 2px;
  }
}

// Redactor
.redactor-editor {
  div,
  p,
  ul,
  ol,
  table,
  dl,
  blockquote,
  pre {
    font-size: auto;
    line-height: auto;
  }
}
#redactor-modal-box {
  z-index: @zindex-modal + 1;
}

// For angular-bootstrap
.nav,
.carousel,
.panel-title a {
  cursor: pointer;
}

// Override bootstrap
body fieldset {
  border: 2px solid @grey2;
  float: left;
  margin-right: 6px;
  font-weight: 300;
  padding: 10px;
  width: 100%;
  legend {
    margin-left: 20px;
    margin-bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    border-bottom: none;
    width: auto;
    color: @grey2;
    font-size: 16px;
  }
}

// FAQ page
#content.faq {
  height: 100%;
  overflow-y: auto;
  nav ul li {
    font-size: 16px;
  }
  dt {
    width: 80%;
    margin-top: 1em;
    font-size: x-large;
  }
  dd {
    width: 80%;
    padding-bottom: 0.5em;
  }
  img {
    display: block;
    margin: 1em;
  }
  sup {
    width: 1.1em;
    height: 1.1em;
    display: inline-block;
    text-align: center;
    background-color: red;
    line-height: 1.1em;
    border-radius: 0.55em;
    color: white;
    margin-left: 0.1em;
    vertical-align: super;
    top: 0;
  }
}
