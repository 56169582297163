/**
 * Global styling (not page specific, but global elements that appear on most pages)
 *  I.e., not common elemens such as selects, etc…but navigation menus, error modal, tags, etc
 */

// Make sure that everything has the same color and height
html,
body,
#page,
#page-wrap,
#content-wrap,
#content,
#content > div {
  height: 100%;
  color: @grey-text;
}

// The error handler modal
#error-handler {
  position: absolute;
  top: 0;
  left: 0;
}
.error-handler-modal {
  .modal-footer {
    margin-top: 0px;
  }
  .forgot-password {
    float: left;
    font-size: 11px;
    line-height: 34px;
    cursor: pointer;
  }
}

#forgot-password-form .modal-body input {
  width: 100%;
}

// Menu iconz
.nav-icon() {
  &:before {
    width: 20px;
    height: 21px;
  }
}
.search() {
  .nav-icon;
}
.dash() {
  .icon-menu-dashboard;
}
.lists() {
  .icon-menu-lists;
}
.data() {
  .icon-menu-data;
}
.tools() {
  .icon-menu-tools;
}

.mobile-icon() {
  background: transparent url("../../assets/assets/_/img/mobile-icons.png");
  width: 37px;
  height: 29px;
}

.search-mobile() {
  .mobile-icon;
  background-position: -80px -10px;
}
.dash-mobile() {
  .mobile-icon();
  background-position: -59px -10px;
}
.lists-mobile() {
  .mobile-icon;
  background-position: -97px -7px;
}
.data-mobile() {
  .mobile-icon;
  background-position: -131px -3px;
}
.tools-mobile() {
  .mobile-icon;
  background-position: -370px -6px;
}

// Mixins & styling for the indicator lights
.indicator-lights() {
  .no-select;
  &.completed {
    background-color: #62a2c8;
  }
  &.positive {
    background-color: #71be8e;
  }
  &.caution {
    background-color: #d78f48;
  }
  &.negative {
    background-color: #e07676;
  }
  &.expired {
    background-color: #313131;
  }
}
.indicator-lights(@circle) when(@circle) {
  width: 0px;
  height: 0px;
  border-radius: 50%;
  border: 11px solid transparent;
  display: inline-block;
  .indicator-lights();
}

// Loading indicators
.loading-indicator {
  margin: 5px;
  position: relative;
  font-size: 35px;
  background: white;
  z-index: 1001;
  .text {
    padding-left: 10px;
  }
  > .top,
  > .right-side,
  > .bottom,
  > .left {
    width: 0%;
    background: #ff6600;
  }
}
.small-loading-indicator {
  .icon-notched-circle {
    width: 14px;
    height: 14px;
    line-height: 14px;
    -webkit-animation: spin 0.9s linear infinite;
    -moz-animation: spin 0.9s linear infinite;
    animation: spin 0.9s linear infinite;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

// Tag styling (primarily for the list manager),
// but also for other pages that use tags
.tag {
  z-index: 2001 !important;
  list-style-type: none;
  font-size: 13px;
  font-weight: 100;
  color: white;
  margin-top: 5px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  float: left;
  .text-truncate();
  &.small {
    width: 10px;
    font-size: 0px;
  }
  &.private {
    &:before {
      content: " ";
      border-top: 15px solid @grey-dark;
      border-left: 15px solid transparent;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    &.dark:before {
      border-top-color: @grey-darkest;
    }
  }
}
[epi-tags] {
  float: left;
  width: 100%;
}
.tag-list {
  .no-select;
  padding: 0px;
  float: left;
  width: 100%;
  overflow: hidden;
  height: 30px;
  .tag {
    position: relative;
    line-height: 25px;
    padding-right: 0px;
    &:after {
      content: " ";
      width: 2px;
      height: 25px;
      position: absolute;
      right: 0px;
      top: 0px;
      background: white;
    }
    .name {
      padding: 0px 3px;
    }
    &.private:before {
      right: 2px;
    }
  }
}
.tag-dropdown {
  .tag-list {
  }
}
.tag-placeholder {
  width: 4px;
  background-color: @grey-darkest;
  list-style-type: none;
}
.tag {
  &.orange {
    background-color: @orange-lighter;
  }
  &.red {
    background-color: @red;
  }
  &.blue {
    background-color: @blue2;
  }
  &.green {
    background-color: @green;
  }
  &.purple {
    background-color: @purple;
  }
  &.yellow {
    background-color: @yellow;
  }
}

.tooltip-inner {
  max-width: 100%;
}

.collapse-triangle {
  cursor: pointer;
  position: absolute;
  right: 25%;
  color: white;
  z-index: 1999;
  top: 55px;
  overflow: hidden;
  margin: auto;
  width: 60px;
  height: 13px;
  .triangle {
    width: 50px;
    height: 15px;
    top: 0px;
    left: 10px;
    display: block;
    text-align: center;
    line-height: 8px;
    position: relative;
    &:before {
      content: " ";
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 13px solid @blue2;
      position: absolute;
      left: 0px;
    }
    .dash {
      color: white;
      font-size: 31px;
      z-index: 1000;
      position: relative;
    }
  }
  .square1,
  .square2 {
    z-index: 10;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 50px;
    top: -11px;
  }
  .square1 {
    left: 5px;
    transform: rotate(-62deg);
    -ms-transform: rotate(-62deg);
    -webkit-transform: rotate(-62deg);
    -o-transform: rotate(-62deg);
    -moz-transform: rotate(-62deg);
  }
  .square2 {
    left: 45px;
    transform: rotate(62deg);
    -ms-transform: rotate(62deg); /* IE 9 */
    -webkit-transform: rotate(62deg); /* Safari and Chrome */
    -o-transform: rotate(62deg); /* Opera */
    -moz-transform: rotate(62deg); /* Firefox */
  }
}

// Empty h4
h4.empty {
  text-align: center;
  font-size: 35px;
  color: @grey-darker;
  font-weight: 100;
}
h5.empty {
  text-align: center;
  font-size: 25px;
  color: @grey-darker;
  font-weight: 100;
}

// Dropdown selector
.dropdown-selector {
  position: absolute;
  top: 25px;
  z-index: 2500;
  // width: 50%;
  h6 {
    float: left;
    // width: 25%;
    background-color: white;
    margin: 0;
    line-height: 36px;
    padding-left: 7px;
    z-index: 2500;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    .caret {
      float: right;
      position: relative;
      &:before {
        color: @grey-darkest;
        position: absolute;
        left: -22px;
      }
    }
  }
  .ui-effects-wrapper {
    clear: both;
  }
  .add {
    -webkit-transition: all 150ms ease-in-out;
    -moz-transition: all 150ms ease-in-out;
    -ms-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    margin-top: 3px;
    margin-left: 3px;
  }
  .selector {
    display: none;
    border: none;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    z-index: 2500;
    position: relative;
    .items {
      min-height: 230px;
      float: left;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      &.single-column {
        padding-top: 15px;
        .item,
        .half {
          width: 100%;
        }
      }
    }
    .half {
      float: left;
      width: 50%;
    }
    .item {
      float: left;
      width: 100%;
      padding-left: 35px;
      padding-right: 10px;
      cursor: pointer;
      .text {
        float: left;
        width: 100%;
        padding-right: 40px;
        .text-truncate();
        line-height: 32px;
        &:hover {
          color: lighten(@grey-darkest, 10%);
        }
      }
      .controls {
        float: right;
        margin-left: -40px;
      }
    }
    h5 {
      padding-left: 15px;
      font-size: 30px;
      position: relative;
      margin-bottom: 0;
      &:after {
        content: " ";
        width: 35px;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 5px;
        background-color: @grey-darker;
      }
      &:before {
        content: " ";
        height: 152px;
        width: 2px;
        position: absolute;
        top: 100%;
        left: 20px;
        background-color: @grey-darker;
      }
    }
  }
  &.open {
    .add {
      color: white;
      margin-left: -48px;
      margin-top: 3px;
    }
    h6 {
      background-color: @orange;
      color: white;
      .caret {
        display: inline;
        &:before {
          color: white;
        }
      }
    }
  }
}

// Filter stuff for select, small select, and input styling
.filter {
  .make-md-column(12);
  padding: 0px;
  input {
    .make-md-column(4);
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-select-holder {
    .icon-arrow-down-after;
    &:after {
      font-size: 7px;
      position: absolute;
      top: 0;
      right: 7px;
      line-height: 41px;
      color: #9a9a9a;
      pointer-events: none;
    }
    select {
      float: left;
      width: 100%;
      font-size: 14px;
      color: @grey-darkest;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: "";
      margin-top: 2px;
      border-radius: 2px;
      -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      border: 2px solid @grey-dark;
      padding-right: 7px;
    }
    &:nth-child(odd) {
      .make-md-column(5);
    }
    &:nth-child(even) {
      .make-md-column(2);
    }
    &:nth-child(odd),
    &:nth-child(even) {
      padding: 0px;
    }
  }
  label {
    font-weight: 400;
  }
  .del {
    position: absolute;
    right: -25px;
    margin-top: 10px;
  }
}
.filter-creator {
  padding-right: 25px;
  .filter {
    clear: left;
    width: 100%;
    .filter-select-holder {
      > select {
        padding-left: 5px;
        margin-top: 5px;
        height: 31px;
        background-color: white;
      }
    }
    input[type="text"] {
      height: 31px;
      margin-top: 5px;
      border-radius: 2px;
      border: 2px solid @grey-dark;
      -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
      &:focus {
        border-color: @orange-darker;
        -webkit-box-shadow: inset 0 0 0 @orange-darker, 0 0 6px @orange-darker;
        box-shadow: inset 0 0 0 @orange-darker, 0 0 6px @orange-darker;
      }
      &.invalid {
        border-color: @red;
      }
    }
    .empty {
      line-height: 40px;
      font-size: 20px;
      color: @grey-dark2;
    }
    .btn,
    .btn.del {
      float: left;
      height: 40px;
      position: absolute;
      margin-top: 5px;
    }
    .del {
      right: -10px;
    }
    .add {
      right: -30px;
      &:before {
        margin-left: 1px;
      }
    }
  }
}

/**
 * Styling for the color picker
 */

.sp-container,
.sp-container button,
.sp-container input,
.sp-color,
.sp-hue,
.sp-clear {
  font: normal @font-size-base @font-family-base, sans-serif;
}

.sp-replacer {
  border: none;
  background-color: transparent;
  &:hover,
  &.sp-active {
    border-color: @grey-darkest;
  }
  .sp-preview {
    border: none;
    width: 16px;
    height: 16px;
  }
  .sp-dd {
    display: none;
  }
}

.sp-container {
  background-color: @grey;
  border-color: @grey-darkest;
  .sp-choose,
  .sp-cancel {
    font-weight: 100;
  }
  .sp-choose {
    .btn;
    background-image: none;
    color: white;
    text-shadow: none;
    text-transform: capitalize;
    &:hover {
      background-image: none;
      background-color: @orange-lighter;
      box-shadow: inset 0 -3px 2px -1px @orange;
      color: white;
      border: none;
      text-shadow: none;
    }
  }
  .sp-cancel,
  .sp-cancel:hover {
    .btn.plain;
    text-transform: uppercase;
    text-decoration: none;
    color: @grey-darkest !important;
  }
}

/**
 * Ng-progress styling
 */
.ngProgressLite-on {
  .ngProgressLite {
    pointer-events: none;
  }

  .ngProgressLiteBar {
    background: firebrick;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    height: 2px;
  }

  .ngProgressLite .ngProgressLiteBarShadow {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px firebrick, 0 0 5px lighten(firebrick, 10%);
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
}

/**
 * Redactor fullscreen
 */

.redactor-box-fullscreen {
  z-index: @zindex-modal + 1 !important;
}
.redactor-dropdown {
  z-index: @zindex-modal + 2 !important;
}
.redactor-toolbar-tooltip {
  z-index: @zindex-modal + 2;
}
.redactor-box,
.redactor-box .redactor-toolbar {
  border-radius: 2px;
}
.redactor-toolbar {
  box-shadow: none;
  li a:hover {
    background-color: @blue3;
  }
}
.redactor-editor {
  border: none;
  border-top: 1px solid #eee;
  div,
  p,
  ul,
  ol,
  table,
  dl,
  blockquote,
  pre {
    font-size: auto;
    line-height: auto;
    background: inherit;
  }
}

/**
 * Wrapper styling
 */

#page {
  width: 100%;
  padding: @navbar-height 0 0 0;
  margin: 0px;
}
/* Side nav */
#side-nav-left,
#side-nav-right {
  position: fixed;
  padding-right: 0px;
  padding-left: 0px;
  height: 100%;
  z-index: 3000;
  cursor: auto;
  > ul > li > a > span {
    display: none;
  }
  a {
    border-radius: 0px;
    color: white;
    text-decoration: none;
    &:focus {
      background-color: inherit;
    }
  }
}
#side-nav-left {
  background-color: @side-nav-left-background;
  width: @side-nav-left-width;
  z-index: 5000;
  li {
    border-top: 1px solid @blue;
    margin-top: 0px;
    position: relative;
    z-index: 3001;
    a:before {
      content: "";
      display: block;
      overflow: hidden;
      width: 30px;
      height: 30px;
      position: absolute;
      padding-left: 1px;
      top: 50%;
      left: 50%;
      margin-top: -14px;
      margin-left: -13px;
      font-size: 20px;
    }
    &:last-child {
      border-bottom: 1px solid @blue;
    }
    &.active a,
    > a:hover {
      background-color: @side-nav-left-li-active;
      padding-right: 26px;
      border-left: 4px solid @blue-lighter;
      &:before {
        left: 26px;
      }
    }
    &.search {
      background-color: @blue-lighter;
      a {
        .search;
      }
    }
    &.menu {
      display: none;
    }
    &.data a {
      .icon-menu-lists;
    }
    &.mapping a {
      .icon-menu-truterritory;
    }
    > a {
      padding: 25px 30px 20px 30px;
      width: 20px;
      height: 20px;
    }
    > a + .tooltip {
      z-index: 5000;
    }
    &.disabled a:hover {
      cursor: pointer;
    }
  }
  .disabled {
    a:before {
      color: darken(white, 20%);
    }
  }
}

#side-nav-right {
  left: 60px;
  background-color: @side-nav-right-background;
  width: @side-nav-right-width;

  h3 {
    text-transform: uppercase;
    color: lighten(@grey-darkest, 10%);
    background-color: @blue2-darker;
    font: 0.8em sans-serif;
    float: left;
    margin-top: 0.2em;
    margin-bottom: 0em;
    padding-left: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-right: 0.2em;
    width: 100%;
    line-height: 1.25em;
    border-top-right-radius: 1em;
    border-top-left-radius: 1em;
    &:hover {
      background-color: @blue2;
      color: white;
    }
  }

  //transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  .bookmark-heading {
    .closed-indicator {
      color: @grey-darkest;
      background-color: @blue2;
      width: 100%;
      float: left;
      line-height: 2em;
      text-align: center;
      vertical-align: middle;
      font-size: 1em;
      padding-top: 0.25em;
      transform: scaleY(0.4);
      transform-origin: top;
      margin-bottom: -1em;

      border-top-right-radius: 1px;
      border-top-left-radius: 1px;

      &:hover {
        background-color: @blue2-lighter;
      }
    }
    .closed-count {
      color: @grey-darkest;
      font-size: smaller;
      margin-left: 0.5em;
    }
  }
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    float: left;
    line-height: 9px;
    > li {
      width: 100%;
      font-size: 14px;
      font-weight: 100;
      margin-top: 0px;

      > ul {
        cursor: auto;
      }

      > a {
        border-top-left-radius: @bookmark-corner-radius;
        border-bottom-left-radius: @bookmark-corner-radius;
        background-color: @side-nav-right-background;
        &.active {
          background-color: @bookmark-background-active;
          &:hover {
            background-color: @bookmark-background-active-hover;
          }
        }
        &:hover {
          background-color: @bookmark-background-hover;
        }
      }

      //& {
      //  > a {
      //    background-color: @side-nav-right-background;
      //    &.active {
      //      background-color: @bookmark-background-active;
      //      &:hover {
      //        background-color: @bookmark-background-active-hover;
      //      }
      //    }
      //    &:hover {
      //      background-color: @bookmark-background-hover;
      //    }
      //  }
      //}
      a {
        width: 100%;
        float: left;
        line-height: 20px;
        padding-left: 15px;
        border-bottom: 1px solid @grey-text;
        &:hover {
          text-decoration: none;
        }
        &.circle {
          > span {
            display: inline-block;
            font-size: 12px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            background-color: @blue2-lighter;
            margin-right: 5px;
            margin-left: -5px;
            text-align: center;
            &.after {
              margin-top: 12px;
              margin-right: 8px;
              float: right;
            }
          }
        }
      }
    }
  }
}
/* Secondary top nav */
#top-nav-secondary {
  margin-bottom: 0px;
  padding-left: @top-nav-secondary-left;
  width: 100%;
  position: fixed;
  height: @navbar-height;
  background-color: @top-nav-secondary-background;
  z-index: 2001;
  border: none;
  h1,
  h2,
  h3,
  h4,
  h5 {
    display: inline;
    float: left;
    margin: 0px;
    font-weight: 100;
    line-height: @navbar-height;
  }
  h3 {
    margin-right: 7px;
  }
  > ul {
    float: left;
    margin: 0;
    padding-left: 9px;
    height: @navbar-height;
    list-style-type: none;
  }
  ul.right {
    float: right;
  }
  li {
    padding: 9px 0px;
    & > a {
      padding: 9px 15px;
      font-weight: 100;
      button {
        margin-top: -9px;
      }
    }
    & > button {
      margin-right: 20px;
    }
    & > input {
      margin-right: 5px;
    }
    button {
      margin-top: 2px;
    }
    button span {
      line-height: 20px;
      padding: 0px;
    }
    span {
      line-height: 40px;
      padding: 0px 10px;
    }
    &.input {
      color: @grey-blue;
    }
  }
  .select-holder {
    height: 32px;
    margin-top: 3px;
    border: 0;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 1;
    background-color: @btn-default-disabled;
    color: #ddd;
    font-style: italic;
  }
  .dropdown-selector {
    color: @grey-darkest;
    top: 10px;
  }
}
#content #top-nav-slider {
  width: 100%;
  padding-top: @navbar-height;
  padding-bottom: @navbar-height;
  background-color: @grey-dark;
  height: auto;
  float: left;
  z-index: 2000;
  box-shadow: inset 6px -3px 15px -7px #000;
  &.overflow-visible {
    overflow: visible;
  }
  > div {
    width: 100%;
    float: left;
  }
  .collapse-triangle {
    width: 60px;
  }
  > .row {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: -5px;
    margin-bottom: 15px;
    .row {
      margin-top: 10px;
    }
  }
}
#top-nav-secondary + #content {
  padding-top: @navbar-height;
}
#content {
  width: 100%;
  padding-left: @top-nav-secondary-left;
  margin: 0px;
  // z-index: 1000;
  float: left;
  position: relative;
  &.has-navbar {
    padding-top: @navbar-height;
  }
  &.full-width {
    padding-left: 75px;
  }
  &.error {
    h1 {
      font-weight: 400;
      font-size: 38px;
    }
  }
  > section {
    > header {
      padding: 0 1em;
      background-color: @blue3;
      color: #fff;
      h2 {
        margin: 0;
        line-height: @line-height-heading;
        font-size: @font-size-h1;
        font-weight: 100;
      }
    }
  }
  .right-wrap {
    height: 100%;
    overflow: hidden;
  }
  .right {
    width: 33%;
    height: 100%;
    overflow: scroll;
    background-color: @grey-dark;
    float: right;
    padding: 25px 25px 20px 25px;
    position: relative;
    // z-index: 2000;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 100;
      color: @grey-darkest;
    }
    label {
      font-weight: 100;
    }
  }
}

/**
 * Navigation styling
 */

header {
  /* Top nav */
  #top-nav {
    background-color: @top-nav-background;
    border: none;
    position: fixed;
    width: 100%;
    top: 0;
    margin-bottom: 0px;
    z-index: 3000;
    #navbar-logo {
      width: 175px;
      margin: 0;
      padding: 0;
      line-height: 50px;
      padding-left: 15px;
      border-right-color: transparent;
      float: left;
      height: 35px;
      margin: 7px 23px;
      position: relative;
      background: transparent url("../../assets/assets/_/img/logo.svg") no-repeat;
      &:before {
        content: " ";
        position: absolute;
        background-color: @blue-lighter;
        height: 90%;
        width: 1px;
        margin-top: 2px;
        right: 0px;
      }
    }
    #page-header {
      > h1 {
        line-height: @navbar-height;
        padding: 0;
        margin: 0;
        font-size: @font-size-h1;
        font-weight: 100;
      }
    }
    #user-menu {
      .icon-arrow-down-after;
      &:after {
        position: absolute;
        right: 8px;
        top: 0px;
        line-height: 60px;
        color: @blue-lighter;
        font-size: 7px;
        pointer-events: none;
      }
      > a {
        padding-right: 25px;
        .text-truncate;
        text-transform: uppercase;
      }
      &:before {
        content: " ";
        position: absolute;
        background-color: @blue-lighter;
        height: 70%;
        width: 1px;
        margin-top: 8px;
      }
      ul.open {
        display: block;
      }
      .version {
        color: @grey-darker;
        padding: 3px 20px 0px 20px;
        font-size: 10px;
      }
    }
  }
  #company {
    .icon-company;
    margin-top: 12px;
    position: relative;
    overflow: hidden;
    padding-left: 20px;
    margin-right: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    width: 200px;
    height: 34px;
    font-weight: normal;
    cursor: auto;
    .company-name {
      .text-truncate;
      width: 100%;
      line-height: 31px;
      pointer-events: none;
      padding-right: 35px;
      padding-left: 6px;
      position: absolute;
    }
    &.select-holder {
      cursor: pointer;
    }
    select {
      color: white;
      opacity: 0;
      option {
        color: black;
        background-color: white;
      }
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
    &:before {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 2px;
      font-size: 18px;
    }
  }
}

// Styles when only a single module is enabled, e.g., mapping
.single-module {
  #side-nav-left {
    display: none;
  }
  #side-nav-right {
    left: 0;
    width: @side-nav-width;
  }
}

#content.no-secondary-menu {
  padding-left: 60px;
}
#top-nav-secondary.no-secondary-menu {
  padding-left: 85px;
}

#report-problem-form {
  textarea {
    height: auto
  }
}

#problem {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
}

// Ensure that the scrollbars are always above everything
.nicescroll-rails {
  z-index: 1001 !important;
}
